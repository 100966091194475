import React from "react";
import gql from "graphql-tag";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import {
  BarGraph,
  Button,
  Pane,
  ScatterPlot,
  Segment,
  Select,
  Spinner,
  Text,
  TextInput,
} from "components/materials";
import { get, map, trim } from "lodash";
import { isBlank, majorScale, t, toaster } from "helpers/utilities";
import { divide } from "helpers/math";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";

const DOCUMENT_COUNT_METRICS_QUERY = gql`
  query DocumentMetricsQuery {
    documentMetrics {
      name
      labels
      datasets {
        label
        data
      }
    }
  }
`;

const DOCUMENT_FIELDS_METRIC_QUERY = gql`
  query DocumentFieldsMetricQuery($type: DocumentTypeName!) {
    documentFieldsMetric(type: $type) {
      name
      labels
      datasets {
        label
        data
      }
    }
  }
`;

const UPLOAD_METRICS_QUERY = gql`
  query UploadMetricsQuery($organizationName: String) {
    uploadMetrics(organizationName: $organizationName) {
      name
      datasets {
        label
        data {
          x
          y
        }
      }
    }
  }
`;

const CLEAR_CACHE_MUTATION = gql`
  mutation ClearCacheMutation {
    clearPickerCache {
      status
    }
  }
`;

const allColors = [
  "#3AAEE3", // teal
  "#FDB952", // orange
  "#4753A4", // dark purple
  "#299CD8", // darker blue
  "#E8A603", // burnt orange
  "#5A85F2", // light blue
];

const documentMetricOptions = [
  {
    name: "Total Counts of Each Document Type",
    value: 0,
  },
  {
    name: "Detailed Count of Each Document Type",
    value: 1,
  },
];

const uploadMetricOptions = [
  {
    name: "Number of Uploads by Page Count",
    value: 0,
  },
  {
    name: "Average Number of Documents per Upload by Page Count",
    value: 1,
  },
  {
    name: "Average Time to Confirm or Split by Page Count",
    value: 2,
  },
  {
    name: "Average Number of Deleted Auto-Splits by Page Count",
    value: 3,
  },
];

const tooltipFormats = {
  Number: (num) => num.toLocaleString("en"),
  Percentage: (num) => `${num * 100}%`,
  Time: (num) => `${num.toLocaleString("en")} seconds`,
};

const formatDocumentTooltip = (tooltipItem, data) => {
  let displayType = "Number";
  if (data.label.includes("Percent")) {
    displayType = "Percentage";
  }

  const { label } = data.datasets[tooltipItem.datasetIndex];
  const value = tooltipFormats[displayType](tooltipItem.yLabel);

  return `${label}: ${value}`;
};

const formatScatterTooltip = (tooltipItem, data) => {
  let displayType = "Number";
  if (data.label.includes("Time")) {
    displayType = "Time";
  }

  const pageCount = tooltipFormats.Number(tooltipItem.xLabel);
  const { label } = data.datasets[tooltipItem.datasetIndex];
  const value = tooltipFormats[displayType](tooltipItem.yLabel);
  const ratio = divide(tooltipItem.yLabel, tooltipItem.xLabel).toFixed(2);

  return `Pages: ${pageCount},   ${label}: ${value},   Ratio: ${ratio}`;
};

function ClearCache({ clearCache, loading }) {
  return (
    <Segment heading="Clear Cache">
      <Pane>
        <Text>
          Clear out picker document data cache. Only click this if you know what
          you are doing!
        </Text>
      </Pane>
      <Button isLoading={loading} onClick={() => clearCache()}>
        Clear Cache
      </Button>
    </Segment>
  );
}

function DocumentCountMetrics() {
  const [documentMetricIndex, setDocumentMetricIndex] = React.useState(0);

  const { data, loading } = useQuery(DOCUMENT_COUNT_METRICS_QUERY);

  if (loading) return <Spinner margin="auto" />;

  const { documentMetrics } = data;
  return (
    <Segment margin={majorScale(4)} width="50%">
      <Select
        value={documentMetricIndex}
        onChange={(event) =>
          setDocumentMetricIndex(parseInt(event.target.value, 10))
        }
      >
        {documentMetricOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </Select>
      <BarGraph
        key="documentMetrics"
        colors={allColors}
        metric={documentMetrics[documentMetricIndex]}
        format={formatDocumentTooltip}
      />
    </Segment>
  );
}

function DocumentFieldsMetrics() {
  const documentTypeOptions = map(DOCUMENT_TYPE_NAME, (type) => ({
    name: t(`documentTypes.${type}`),
    value: type,
  }));
  const [
    documentFieldsMetricDocumentType,
    setDocumentFieldsMetricDocumentType,
  ] = React.useState(DOCUMENT_TYPE_NAME.INVOICE);

  const [fetchDocumentFieldsData, { data, loading, called }] = useLazyQuery(
    DOCUMENT_FIELDS_METRIC_QUERY
  );
  if (!called)
    fetchDocumentFieldsData({
      variables: { type: DOCUMENT_TYPE_NAME.INVOICE },
    });

  if (loading || !data) return <Spinner margin="auto" />;
  const { documentFieldsMetric } = data;
  return (
    <Segment margin={majorScale(4)} width="50%">
      <Select
        value={documentFieldsMetricDocumentType}
        onChange={(e) => {
          fetchDocumentFieldsData({ variables: { type: e.target.value } });
          setDocumentFieldsMetricDocumentType(e.target.value);
        }}
      >
        {documentTypeOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </Select>
      <BarGraph
        key="fieldInputMetrics"
        colors={allColors}
        max={1}
        metric={documentFieldsMetric}
        format={formatDocumentTooltip}
      />
    </Segment>
  );
}

function UploadMetrics() {
  const [uploadMetricIndex, setUploadMetricIndex] = React.useState(0);
  const [uploadMetricOrg, setUploadMetricOrg] = React.useState("");
  const [fetchUploadData, { data, loading, called }] =
    useLazyQuery(UPLOAD_METRICS_QUERY);
  if (!called) fetchUploadData();

  if (loading || !data) return <Spinner margin="auto" />;

  const { uploadMetrics } = data;
  return (
    <Segment margin={majorScale(4)} width="50%">
      <Pane display="flex">
        <Select
          value={uploadMetricIndex}
          onChange={(event) =>
            setUploadMetricIndex(parseInt(event.target.value, 10))
          }
        >
          {uploadMetricOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </Select>
        <TextInput
          marginLeft={majorScale(1)}
          onChange={(event) => setUploadMetricOrg(event.target.value)}
          placeholder="Optional Organization Name"
          value={uploadMetricOrg}
        />
        <Button
          marginLeft={majorScale(1)}
          onClick={() => {
            const organizationName = isBlank(uploadMetricOrg)
              ? ""
              : trim(uploadMetricOrg);
            fetchUploadData({
              variables: { organizationName },
            });
          }}
        >
          Get for Organization
        </Button>
      </Pane>
      <ScatterPlot
        key="uploadMetrics"
        colors={allColors}
        metric={uploadMetrics[uploadMetricIndex]}
        format={formatScatterTooltip}
      />
    </Segment>
  );
}

function AdminPage() {
  const [clearCache, { loading }] = useMutation(CLEAR_CACHE_MUTATION, {
    onCompleted: (result) => {
      const { status } = get(result, "clearPickerCache", {});
      if (status === "OK") {
        toaster.success("Cache cleared");
      } else if (status === "ERROR") {
        toaster.danger("Could not clear cache");
      }
    },
  });
  return (
    <React.Fragment>
      <ClearCache clearCache={clearCache} loading={loading} />
      <Pane display="flex">
        <DocumentCountMetrics />
        <DocumentFieldsMetrics />
      </Pane>
      <Pane display="flex">
        <UploadMetrics />
      </Pane>
    </React.Fragment>
  );
}

export default AdminPage;
